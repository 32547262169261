import React from "react";
import SEO from "../../../components/atoms/seo";

import Layout from "../../../components/organisms/layout";
import Heading from "../../../components/molecules/heading";
import ChatBot from "../../../components/molecules/chat-bot";

import content from "../../../data/pages/styleguide/components/chat-bot.json";

const StyleguideComponentsChatBotPage = () => (
  <Layout>
    <SEO title="Styleguide" />
    <Heading title="Styleguide - Components - Chat Bot" weight="light" />
    <div className="p-styleguide">
      <ChatBot
        content={content.chatBot}
        classNames="l-spacing__m-tb--40"
        // UTMparams={this.state.UTMparams}
      />
    </div>
  </Layout>
);

export default StyleguideComponentsChatBotPage;
